import { useMsal } from "@azure/msal-react";
import React from "react";
import { IDialogLoading } from "./components/DialogLoading";

interface IDocuklaudApi {
  getHeaders: () => Promise<any>;
  getInstances: (props: {
    lastId?: string;
    sortAsc?: string;
    sortField?: string;
    view?: string;
  }) => Promise<any>;
  getSuscriptionByInstance: (itemid: string) => Promise<any>;
  addSuscription: (props: any) => void;
  getRoleAccount: () => Promise<string[]>;
  getSuscriptionCodes: (props: {
    lastId?: string;
    sortAsc?: string;
    sortField?: string;
    field?: string;
    value?: string;
  }) => Promise<any>;
  addSuscriptionCode: (purpose: string, plan: string) => void;
  addUpdateInstance: (instanceid: string, data: any) => Promise<any>;
  getSuscriptionPeriods: (id: string) => Promise<any>;
  addTrialDays: (id: string, days: number) => void;
}

export const DataContext = React.createContext({});

interface IDataContext {
  sidebarShow: any;
  setSidebarShow: any;
  sidebarUnfoldable: any;
  setSidebarUnfoldable: any;
  docuklaudApi: IDocuklaudApi;
  dialogLoadingProps: IDialogLoading;
  setDialogLoadinProps: React.Dispatch<React.SetStateAction<IDialogLoading>>;
}
const API_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3002"
    : process.env.REACT_APP_API_URL;

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
  console.log("API:", process.env.REACT_APP_API_URL);
  const [sidebarShow, setSidebarShow] = React.useState();
  const [sidebarUnfoldable, setSidebarUnfoldable] = React.useState();
  const { instance, accounts } = useMsal();
  const [dialogLoadingProps, setDialogLoadinProps] =
    React.useState<IDialogLoading>({});
  const docuklaudApi: IDocuklaudApi = {
    getHeaders: async () => {
      const response = await instance.acquireTokenSilent({
        scopes: ["api://docuklaud-backend/access_as_user"],
        account: accounts[0],
      });
      return {
        Authorization: `Bearer ${response.accessToken}`,
        "Content-Type": " application/json",
      };
    },

    getRoleAccount: async () => {
      const response = await instance.acquireTokenSilent({
        scopes: ["api://docuklaud-backend/access_as_user"],
        account: accounts[0],
      });
      const info = parseJwt(response.accessToken);

      return info && info.roles ? info.roles : [];
    },

    getInstances: async ({ lastId, sortAsc, sortField, view }) => {
      const headers = await docuklaudApi.getHeaders();
      const resp = await fetch(
        `${API_URL}/api/suscription/items?lastId=${lastId}&sortAsc=${sortAsc}&sortField=${sortField}&view=${view}`,
        {
          headers,
          method: "GET",
        }
      );
      return getResponseData(resp);
    },

    getSuscriptionByInstance: async (itemid) => {
      const headers = await docuklaudApi.getHeaders();
      const resp = await fetch(`${API_URL}/api/suscription/items/${itemid}`, {
        headers,
        method: "GET",
      });
      return getResponseData(resp);
    },
    addSuscription: async (props) => {
      const headers = await docuklaudApi.getHeaders();

      const resp = await fetch(`${API_URL}/api/suscription/add`, {
        headers,
        method: "POST",
        body: JSON.stringify(props),
      });
      return getResponseData(resp);
    },
    getSuscriptionCodes: async ({
      lastId,
      sortAsc,
      sortField,
      field,
      value,
    }) => {
      const headers = await docuklaudApi.getHeaders();
      const resp = await fetch(
        `${API_URL}/api/trialcodes/items?lastId=${lastId}&sortAsc=${sortAsc}&sortField=${sortField}&field=${field}&value=${value}`,
        {
          headers,
          method: "GET",
        }
      );
      return getResponseData(resp);
    },
    addUpdateInstance: async (instanceid, data) => {
      const headers = await docuklaudApi.getHeaders();
      const resp = await fetch(
        `${API_URL}/api/suscription/updateoptions/${instanceid}`,
        {
          headers,
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      return getResponseData(resp);
    },
    addSuscriptionCode: async (purpose, plan) => {
      const headers = await docuklaudApi.getHeaders();
      const resp = await fetch(`${API_URL}/api/trialcodes/add`, {
        headers,
        method: "POST",
        body: JSON.stringify({ purpose, plan }),
      });
      return getResponseData(resp);
    },
    getSuscriptionPeriods: async (id) => {
      const headers = await docuklaudApi.getHeaders();
      const resp = await fetch(`${API_URL}/api/suscription/periods/${id}`, {
        headers,
        method: "GET",
      });
      return getResponseData(resp);
    },
    addTrialDays: async (id, days) => {
      const headers = await docuklaudApi.getHeaders();
      const resp = await fetch(
        `${API_URL}/api/suscription/extend-trial/${id}`,
        {
          headers,
          method: "POST",
          body: JSON.stringify({ days }),
        }
      );
      return getResponseData(resp);
    },
  };

  function getResponseData(resp: Response) {
    return new Promise((resolve, reject) => {
      if (resp.ok) {
        resp.json().then((data) => {
          resolve(data);
        });
      } else {
        reject(resp.statusText);
      }
    });
  }
  function parseJwt(token: string) {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      return null;
    }
  }

  const contextValue = {
    sidebarShow,
    setSidebarShow,
    sidebarUnfoldable,
    setSidebarUnfoldable,
    docuklaudApi,
    setDialogLoadinProps,
    dialogLoadingProps,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};

export const useData = () => React.useContext(DataContext) as IDataContext;
